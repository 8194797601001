<template>
  <div class="bg-ligteColor fixed p-5 md:container border-0 rounded-xl animate-fadeInDown m-5 md:m-0 top-28 md:top-[20%] md:left-[40%] md:w-[500px] z-[999] shadow-black shadow-md">
    <h3 class="text-xl text-bold text-black">
      <i class="fa-solid fa-cookie-bite"></i> Cookiebeleid
    </h3>

    <p class="text-textColor">
      Wij maken gebruik van cookies om de website-ervaring te verbeteren met behulp van Posthog en Google Analytics.
      Wij maken ook gebruik van Session replay, dit doen wij om de website te optimaliseren.
      <br />
      U kunt ervoor kiezen om cookies te accepteren of te weigeren. Voor meer informatie verwijzen we u naar de
      <router-link to="/privacyverklaring">Privacy beleid</router-link>.
    </p>

    <div class="grid items-center">
      <button
        @click="acceptCoockie"
        class="border-hoverColor border-2 p-3 mt-4 rounded-xl hover:bg-hoverColor transition duration-300 text-black"
      >
        <i class="fa-solid fa-cookie-bite"></i> Accepteren
      </button>
      <button @click="weigerCoockie" class="block p-2 m-2">Weigeren</button>
    </div>
  </div>
</template>

<script setup>
import { inject, defineEmits } from 'vue';

// Haal PostHog op via inject()
const posthog = inject('$posthog');
const emit = defineEmits(['hideBanner']);

const acceptCoockie = () => {
  if (posthog) {
    console.log("Cookies geaccepteerd, tracking ingeschakeld.");
    posthog.opt_in_capturing(); // Zet tracking aan
    posthog.capture('$opt_in'); // Extra logging event voor analytics
    posthog.startSessionRecording(); // Start session replay als je dit gebruikt
    emit("hideBanner");
  } else {
    console.error("❌ PostHog is niet geladen.");
  }
};

const weigerCoockie = () => {
  if (posthog) {
    console.log("Cookies geweigerd, tracking uitgeschakeld.");
    posthog.opt_out_capturing(); // Zet tracking uit
    posthog.capture('$opt_out'); // Extra logging event voor analytics
    emit("hideBanner");
  } else {
    console.error("❌ PostHog is niet geladen.");
  }
};
</script>
