<script setup>
import { onMounted } from 'vue';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


onMounted(() => {

  let mm = gsap.matchMedia();
  const sections = gsap.utils.toArray('.content');
  const container = document.querySelector('#horizontal');
  

  mm.add("(min-width: 800px)", () => {
    // Check of container en sections gevonden zijn
    if (container && sections.length > 0) {
      // Maak een horizontale scroll animatie
      gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        ease: 'none',
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: 1,
          snap: 1 / (sections.length - 1),
          end: () => `+=${container.offsetWidth}`
        }
      });
    }
  });


  gsap.to('.steranimatie', {
    rotation: 360,
    scrollTrigger: {
        trigger: ".section3",
        markers: false,
        start: 'top bottom',
        end: 'top -500%',
        scrub:true,
    },
  });

  gsap.to('.arrowanimatie', {
    rotation: 120,
    scrollTrigger: {
        trigger: ".section3",
        markers: false,
        start: 'top bottom',
        end: 'top -500%',
        scrub:true,
    },
  });



  gsap.to('.steranimatie1', {
    rotation: 360,
    scrollTrigger: {
        trigger: ".section4",
        markers: false,
        start: 'top bottom',
        end: 'top -1200%',
        scrub:true,
    },
  });
  

  
});
</script>

<template>
  <div class="">
  <div class="w-[100vw] h-[100vh] relative overflow-hidden">
  <!-- Video als achtergrond -->
   <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/geoptimaliseerd1.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video> 

  <img fetchpriority="high" src="../assets/optimaized/headerimg.webp" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">

  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative flex items-center justify-center text-donkerColor mt-52 opacity-80">
   <div class=" text-bold text-center">
    <h1 data-aos="fade-left" data-aos-duration="1200" class="lg:text-5xl pb-5">SwensSoftware</h1>
    <h2 data-aos="fade-right" data-aos-duration="1200" class="pb-3">Maatwerk Software</h2>
    <router-link data-aos="fade-left" data-aos-duration="1200" class='text-textColor ml-1 border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full hover:bg-hoverColor transition ease-in-out duration-300 mr-2' to="/contact"> Contact <i class="fa-solid fa-arrow-right"></i></router-link>
    <router-link data-aos="fade-right" data-aos-duration="1200" class='text-textColor ml-1 border-2  border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full hover:bg-hoverColor transition ease-in-out duration-300 mr-2' to="/diensten/web-applicatie"> Diensten <i class="fa-solid fa-arrow-right"></i></router-link>
   
    </div>
    
  </div>
 
</div>

  <div id='diensten' class="w-screen h-full lg:h-[100vh] relative">
    <div class="bg-ligteColor grid lg:grid-cols-3 lg:grid-rows-3" >
            
            <div  class="hidden lg:grid">
                <h1  class="text-textColor text-3xl font-semibold md:-rotate-90 absolute top-[50%] left-10"> Onze diensten</h1>
            </div>
      
        <div class="grid md:grid-cols-2 md:col-span-2 gap-2   p-5">
            
            <div data-aos="fade-right" class="grid place-content-center border-2 border-solid border-black p-2 ">
                <div class="flex justify-end">
                    <router-link to="/diensten/web-applicatie" class=""> 
                      <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i>
                        <span class="sr-only">Bekijk Web applicatie</span>
                    </router-link>
                </div>
                <div class="text-textColor my-5">
                    <h1 class='mb-5 text-textColor text-2xl'>Web applicatie Development</h1>
                    <p class="text-textColor">Bied gebruikers toegang tot afgeschermde data en functies via standaard webbrowsers.</p>
                </div>
                <div class="flex justify-end">
                    <span class="text-[33px] font-bold text-textColor">/ 1</span>
                </div>
            </div>

          
        
            <div data-aos="fade-left" class="grid place-content-center border-2 border-solid border-black p-2 ">
                <div class="flex justify-end">
                    <router-link to="/diensten/api-koppeling" class=""> 
                        <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i>
                         <span class="sr-only">Bekijk API koppeling</span>
                    </router-link>
                </div>
                <div class="text-textColor my-5">
                    <h1 class='mb-5 text-textColor text-2xl'>API Koppelingen</h1>
                    <p>Ontwikkeling en management van schaalbare API-koppelingen voor naadloze systeemintegratie.</p>
                </div>
                <div class="flex justify-end">
                    <span class="text-[33px] font-bold text-textColor">/ 2</span>
                </div>
            </div>

        
            <div data-aos="fade-right" class=" grid place-content-center border-2 border-solid border-black p-2 ">
                <div class="flex justify-end">
                    <router-link to="/diensten/portaal" class=""> 
                      <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i>
                      <span class="sr-only">Bekijk portaal</span>
                    </router-link>
                  
                </div>
                <div class="text-textColor my-5">
                    <h1 class='mb-5 text-textColor text-2xl'>Portaal ontwikkeling</h1>
                    <p class="text-textColor">Alle informatiestromen gebundeld op één online plek en toegankelijk gemaakt met een portaal.</p>
                </div>
                <div class="flex justify-end">
                    <span class="text-[33px] font-bold text-textColor">/ 3</span>
                </div>
            </div>

              <div data-aos="fade-left" class=" grid place-content-center border-2 border-solid border-black p-2 ">
                  <div class="flex justify-end">
                      <router-link to="/diensten/online-platform" class=""> 
                        <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition"></i>
                        <span class="sr-only">Bekijk Platform</span>
                      </router-link>
                  </div>
                  <div class="text-textColor my-5">
                      <h1 class='mb-5 text-textColor text-2xl'>Platform ontwikkelen</h1>
                      <p class="text-textColor">Laat meerdere gebruikers via jouw online platform informatie delen, of breng vraag en aanbod samen.</p>
                  </div>
                  <div class="flex justify-end">
                      <span class="text-[33px] font-bold text-textColor">/ 4</span>
                  </div>
              </div>

        </div>
        
    </div>
  </div>

  <section id="horizontal" class="flex section3">
    <div class="content shrink-0 grid place-items-center w-[100vw] h-[100vh] relative ">
     
      <div class="grid lg:grid-cols-2   ">
            <div class="md:h-[100vh] grid place-items-center">
              
                <div data-aos="fade-right" class="grid place-items-start md:pl-[30px] pl-5 container mx-auto" >
                    <h2 class="text-black text-2xl pb-3 ">Software laten maken? Kies <br> voor  maatwerk IT oplossingen.</h2>
                    <p class="w-[90%] lg:w-[50%] ">Wil je software laten maken die perfect aansluit op jouw bedrijf? Bij SwensSoftware ontwikkelen we maatwerk software die jouw processen efficiënter maakt. Of je nu een intern systeem, een mobiele app of een complete IT-oplossing nodig hebt, wij zorgen voor een gebruiksvriendelijke en schaalbare oplossing.</p>
                    <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/diensten/app-applicatie"> Lees meer.. <i class="fa-solid fa-arrow-right"></i></router-link></button>
                  
                   
                </div>
            </div>
            <div class="bg-donkerColor h-[100vh] relative hidden lg:block">

                <div class="bg-ligteColor h-[60%] w-[40%] rounded-xl absolute top-[15%] grid -left-[20%] border-4 border-donkerColor">
                    <div class="text-center pt-3">
                        <h1 class=" text-black text-[50px] ">Welcome <br> Back  </h1>
                    </div>
                    <div class="grid place-items-center mb-5">
                        <img loading="lazy" src="../assets/halfstar.png" alt="Maatwerk software jouw IT oplossing" laz>
                    </div>

                    <div class=" grid place-items-center mb-10">
                        <div class="bg-donkerColor text-white rounded-full h-10 w-[60%] flex justify-start pl-4 pt-1.5"><p>User Name</p></div>
                        <div class="bg-donkerColor text-white rounded-full h-10 w-[60%] flex justify-start pl-4 pt-1.5"><p>Password</p></div>
                    </div>

                    <div class="border-t-2 border-black grid place-items-center">
                        <p>Log in</p>
                    </div>
                </div>

                <img loading="lazy" class=" absolute top-10 left-[30%] steranimatie" src="../assets/ster2.png" alt="Maatwerk software jouw IT oplossing">
                <img loading="lazy" class=" absolute top-[25%] left-[50%]" src="../assets/swingarrow.png" alt="Maatwerk software jouw IT oplossing">
                <img loading="lazy" class=" absolute bottom-[8%] left-[8%]" src="../assets/vlek.png" alt="Maatwerk software jouw IT oplossing">
                <img loading="lazy" class=" absolute bottom-[10%] right-[8%] w-[100px] arrowanimatie " src="../assets/arrowtopleft.png" alt="Maatwerk software jouw IT oplossing">
            </div>
        </div>
      

        <img loading="lazy" class="absolute -bottom-[4rem] -left-10 steranimatie md:hidden" src="../assets/ster2.png" alt="Maatwerk software jouw IT oplossing">
    </div>
    
    <div class="hidden sm:grid content shrink-0 section4 md:grid-cols-2 w-[100vw] h-[100vh] ">
      
      <div class="bg-donkerColor grid place-content-center text-white">
        <div class="pl-5 w-[80%] relative container mx-auto">
          <h1 class="text-2xl pb-3">Weet je al waar je <br>naar opzoek bent?</h1>
          <p>Elke onderneming is uniek en verdient een softwareoplossing die naadloos aansluit bij de bedrijfsprocessen. Met maatwerk software profiteer je van:</p>
          <ul>
            <li>✅ Een IT-oplossing die precies doet wat jij nodig hebt</li>
            <li>✅ Geen onnodige functies of beperkingen zoals bij standaard software</li>
            <li>✅ Schaalbaarheid – de software groeit mee met je bedrijf</li>
            <li>✅ Efficiëntie en automatisering, waardoor je tijd en kosten bespaart</li>
          </ul>
          <p> Bij Swens Software combineren we technische expertise met jouw wensen, zodat we een IT-oplossing ontwikkelen die écht werkt. </p>
          <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
          
        </div>
    
          <img class=" absolute bottom-[10%] left-[18%] w-[150px] hidden lg:block steranimatie1" src="../assets/ster2.png" alt="Maatwerk software jouw IT oplossing">
      </div>
     
       <img loading="lazy" src="../assets/optimaized/7839.webp" alt="Maatwerk software jouw IT oplossing"  class="h-full w-full">
       
   

    </div>

 
  </section>

  

</div>


</template>

<style scoped>
body {
  overscroll-behavior: none;
  height: 100vh;
  margin: 0;
}

@media screen and (min-width: 800px) {
  #horizontal {
  display: flex;
  width: 200vw;
  }
  #horizontal1 {
    display: flex;
    width: 200vw;
  }
}



</style>
