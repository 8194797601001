
<script setup>
import { useHead } from '@vueuse/head'

useHead({
title: 'Portaal laten maken.',
meta: [
   {name: 'description' , content: 'Portaal maken voor je onderneming? Dan ben je bij SwensSoftware bij het juiste adres'}
],
link: [
   { rel: 'canonical', href: 'https://swenssoftware.nl/diensten/portaal' },
],
})
</script>

<template>
<div>
   <div class="container mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
   
      <img fetchpriority="high" data-aos="fade-right" data-aos-duration="1200" src="../assets/portalapplicatie.jpg" alt="Portaal laten maken" class="rounded-xl h-72 md:h-[60dvh] w-full">
   
      <div  data-aos="fade-left" data-aos-duration="1200" class="px-5 lg:px-0 text-donkerColor">
         <span class="text-orange-500 text-bold text-lg">De sleutel tot efficiënter werken</span>
         <h2 class="md:py-5 py-3">Een Portaal laten maken.</h2>
         <p class="lg:w-[80%]">Wil je een portaal laten maken dat jouw bedrijfsprocessen stroomlijnt en efficiënter maakt? Een zakelijk portaal biedt medewerkers, klanten of partners een centrale plek om eenvoudig en veilig toegang te krijgen tot belangrijke informatie en tools. Of het nu gaat om een portaal voor talent, een klantensysteem of een intern platform, een goed ontwikkeld portaal verhoogt de productiviteit en bespaart tijd.</p>
         <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="/contact"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
      </div>
   </div>


   <div class="container mx-auto grid lg:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
      <div class=" grid place-content-center lg:w-[80%] px-5 lg:px-0 text-donkerColor">

         
         <h1 class="lg:w-[80%] mb-5">Wat moet je denken bij een portaal?</h1>
         <p>Een portaal (of portal) is een online platform waar gebruikers op een veilige en efficiënte manier informatie kunnen beheren, uitwisselen en gebruiken. Denk aan een mijn zakelijk portal waar klanten hun facturen en gegevens inzien, of een portaal voor talent waar sollicitanten eenvoudig hun documenten kunnen uploaden en beheren.</p>
      </div>

      <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor" data-aos="fade-up"  data-aos-duration="500">
           <div class="text-black">
               
               <i class="fa-solid fa-users lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Mijn portaal</h2>
               <p>Een op maat gemaakte omgeving voor klanten of medewerkers.</p>
           </div>

         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor"  data-aos="fade-up"  data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-gears lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Portaal voor talent</h2>
               <p>Een platform waar HR-medewerkers eenvoudig sollicitanten en medewerkers beheren.</p>
            </div>
         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor" data-aos="fade-up" data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-cart-shopping lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Zakelijk portaal</h2>
               <p>Een veilige omgeving voor communicatie, data-opslag en samenwerking.</p>
            </div>
         </div>

         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-xl shadow-donkerColor" data-aos="fade-up" data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-handshake lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:*:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Klant portaal</h2>
               <p>Klanten krijgen eenvoudig toegang tot hun gegevens, bestellingen en facturen.</p>
            </div>
         </div>
       
      </div>
   </div>


   <div class="w-[100vw]  h-fit relative overflow-hidden">
  <!-- Video als achtergrond -->
  <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/geoptimaliseerd.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <img loading='lazy' src="../assets/optimaized/headerimg.webp" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">
  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative text-donkerColor pt-12 ">
 
   <div class=" text-center">
      <h3 class="text-3xl">Waarom een Portaal Ontwikkelen?</h3>
   </div>

   <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center  gap-10 p-10">
      <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-right">
           <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-crosshairs bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Efficiëntie</h2>
           <p>Alles op één plek, geen onnodige e-mails of papieren administratie.</p>
      </div>

      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-gears  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Veiligheid</h2>
           <p> Alleen geautoriseerde personen hebben toegang tot gevoelige informatie.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto " data-aos="fade-up-right">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-globe  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Toegankelijkheid</h2>
           <p>Gebruikers kunnen altijd en overal inloggen.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-handshake  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Gebruiksvriendelijkheid</h2>
           <p>Een intuïtieve interface zorgt voor een soepele gebruikerservaring.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-handshake  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Schaalbaarheid</h2>
           <p>Je portal groeit mee met je bedrijf en behoeftes.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-handshake  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Integraties</h2>
           <p>Koppelingen met andere software zoals CRM, HR-systemen of boekhoudprogramma’s.</p>
        
      </div>
   </div>

   
  </div>
 
</div>


<div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
   
      <div class="w-screen md:absolute md:top-0">
           
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block animate-scroll">
                    <img loading='lazy' src="../assets/logo.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/Vite.svg" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/tailwindcss.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/firebase.webp" alt="maatwerk software portal maken" class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/GithubLogo.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/nodeJS.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/figmaAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/relumeAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    
                    <img loading='lazy' src="../assets/logo.png" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/Vite.svg" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/tailwindcss.png" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/firebase.webp" alt="maatwerk software portal maken"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/GithubLogo.png" alt="maatwerk software portal maken" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/nodeJS.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/figmaAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/relumeAfbeelding.png" alt="maatwerk software portal maken"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
        <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

            <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
               <img loading='lazy' src="../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
            </div>

            <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
               <h1 class="pb-5">
                  Online Portaal laten maken?</h1>
               <p class="md:w-[90%] py-3 z-50">Wil je een portaal laten maken dat jouw bedrijf naar een hoger niveau tilt? Bij Swens Software ontwikkelen we op maat gemaakte portalen die jouw bedrijfsprocessen automatiseren en efficiënter maken.</p>
               
               <ul>
                  <li><span class='text-white'>✔</span> Maatwerk oplossingen – Een portaal ontwikkelen dat precies bij jouw bedrijf past.</li>
                  <li><span class='text-white'>✔</span> Veilig en betrouwbaar – Wij zorgen voor optimale bescherming van je data.</li>
                  <li><span class='text-white'>✔</span> Gebruiksvriendelijke interfaces – Intuïtief en eenvoudig in gebruik.</li>
                  <li><span class='text-white'>✔</span> Schaalbare technologie – Jouw zakelijk portaal groeit met je onderneming mee.</li>
               </ul>
               <br>
               <p class='w-[70%] md:w-auto'>Wil jij een zakelijk portaal laten maken dat jouw bedrijfsvoering efficiënter maakt? Kies voor een maatwerk portal dat perfect aansluit bij jouw wensen. Neem contact op met Swens Software en ontdek de mogelijkheden! 
               </p>
               <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
          
            </div>

            <div class="absolute -bottom-[9rem] right-0 sm:pl-10 sm:bg-hoverColor rounded-l-full md:hidden sm:block z-10">
               <img loading='lazy' src="../assets/LeunSwen.png" alt="Contact SwensSoftware" class="">
            </div>
            
         </div>
   

  
</div>

</div>
</template>


<style scoped>
@keyframes scrollanimatie {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: scrollanimatie 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
