<script setup>
import { onMounted } from 'vue';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


onMounted(() => {

  gsap.to('.steranimatie', {
    rotation: 360,
    scrollTrigger: {
        trigger: ".section3",
        markers: false,
        start: 'top bottom',
        end: 'top -1000%',
        scrub:true,
    },
  });
  
  gsap.to('.arrowanimatie', {
    rotation: 360,
    scrollTrigger: {
        trigger: ".section3",
        markers: false,
        start: 'top bottom',
        end: 'top -1000%',
        scrub:true,
    },
  });

});

</script>

<template>
            
    <div class='container mx-auto'>
        
        <div class="grid lg:grid-cols-2 pt-[20vh] min-h-[100vh] place-content-center">
            
            <div class="px-5 md:px-0 pb-5">
                <h2 class='text-xl'>Heee, Ik ben  Swen te Mebel</h2>
                <p class="mb-5">
                    Softwareontwikkeling is mijn passie. Ik ben constant bezig met het bouwen en verbeteren van functionele en slimme applicaties. 
                    Of het nu gaat om het doorontwikkelen van bestaande projecten of het opzetten van iets compleet nieuws – ik denk met je mee en zorg ervoor dat alles naadloos aansluit op jouw wensen. 
                    Samen vertalen we jouw ideeën naar een krachtig en gebruiksvriendelijk softwarepakket waarmee je het maximale uit je onderneming haalt.
                    Met een team van zelfstandigen ben kunnen wij jou wensen waar maken. 
                </p>
                <a class='text-textColor border-2  border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full hover:bg-hoverColor transition ease-in-out duration-300 ' href='#meerovermij'>Meer over mij <i class="fa-solid fa-arrow-down"></i> </a> 
                
            </div>
            
            <div class='bg-donkerColor rounded-l-full w-auto h-92 grid place-content-end relative'>
                <img loading="lazy" class=" absolute bottom-2/4 left-5 steranimatie" src="../assets/ster2.png" alt="Maatwerk software jouw IT oplossing">
                <img src='@/assets/staanSwen.png' alt='Swen te Mebel - SwensSoftware' class="lg:w-72 w-52">
                <img loading="lazy" class=" absolute bottom-[10%] left-[30%] w-[100px] arrowanimatie rotate-180 " src="../assets/arrowtopleft.png" alt="Maatwerk software jouw IT oplossing">
            </div>
        </div>
    
        <div id='meerovermij' class="py-32 px-5 lg:px-0 ">
            <h2 class='text-2xl'>Wat je zoal moet weten<br> over mijn aanpak.</h2>
            <div class="pt-10 ">
                <details name="accordion" >
                    <summary class="flex justify-between items-center border-r-2 border-hoverColor pr-5 py-2 pl-1">Ik ben geen stilzitter.<i class="fa-solid fa-arrow-down py-2 px-3 bg-donkerColor text-ligteColor rounded-full hover:bg-hoverColor transition duration-300 ease-in-out"></i> </summary>
                    <p class="md:pr-72 px-5 pb-10 border-r-2 h-fit border-hoverColor">
                    Ik kan niet stil zitten, Ik ben dan ook altijd bezig met de nieuwste ontwikkelingen en het ontwikkelen van nieuwe en bestaande projecten om dit weer naar een hoger niveau te tillen. Maar ook kan ik wel rustig een bakje koffie drinken en luisteren naar de wensen van mijn klanten. Want samen moeten we toch een mooi reustaat behalen waar iedereen wat aan heeft.   
                    </p>
                </details>
    
                  <details name="accordion" >
                    <summary class="flex justify-between items-center border-l-2 border-t-2 border-hoverColor pr-5 py-2 pl-1">Waarom kiezen voor SwensSoftware? <i class="fa-solid fa-arrow-down py-2 px-3 bg-donkerColor text-ligteColor rounded-full hover:bg-hoverColor transition duration-300 ease-in-out"></i> </summary>
                    <p class=" md:pr-72 px-5 pb-10  border-l-2 h-fit border-hoverColor">
                        Bij SwensSoftware draait alles om maatwerk en kwaliteit. Ik neem de tijd om jouw bedrijf, visie en doelen écht te begrijpen, zodat ik software ontwikkel die perfect aansluit op jouw behoeften. Geen standaardoplossingen, maar schaalbare en veilige applicaties die meegroeien met jouw onderneming.
                        Daarnaast werk ik transparant en communicatief. Jij wordt betrokken bij elke stap van het proces, volgens de SCRUM methode, zodat we samen tot het beste resultaat komen. Mijn passie voor technologie en doorzettingsvermogen zorgen ervoor dat ik altijd een oplossing vind – ook bij complexe uitdagingen.
                        
                    </p>
                </details>
    
                  <details name="accordion" >
                    <summary class="flex justify-between items-center border-r-2 border-t-2 border-hoverColor pr-5 py-2 pl-1">Kwaliteit boven kwantiteit.<i class="fa-solid fa-arrow-down py-2 px-3 bg-donkerColor text-ligteColor rounded-full hover:bg-hoverColor transition duration-300 ease-in-out "></i> </summary>
                    <p class=" md:pr-72 px-5 pb-10  border-r-2  h-fit border-hoverColor ">
                        Bij softwareontwikkeling gaat het niet om snelheid, maar om zorgvuldigheid. Ik neem de tijd om code te schrijven die niet alleen werkt, maar ook efficiënt, veilig en toekomstbestendig is. 
                        Dit betekent dat ik gestructureerd te werk ga, met oog voor performance. Door slimme keuzes te maken in technologie en architectuur, zorg ik ervoor dat jouw software niet alleen vandaag, maar ook in de toekomst optimaal blijft presteren.
                    </p>
                </details>
    
            
            </div>
        </div>
    </div>
 </template>
 
 <script setup>
 
 </script>
