
<script setup>
import { useHead } from '@vueuse/head'

useHead({
title: 'Jouw API koppeling die je nodig hebt',
meta: [
   {name: 'description' , content: 'Opzoek naar een API koppeling voor jouw onderneming? Dan ben je bij ons op het juiste adres. Wij regelen jouw API koppeling.'}
],
link: [
   { rel: 'canonical', href: 'https://swenssoftware.nl/diensten/api-koppeling' },
],
})
</script>

<template>
   <div>
   <div class="container mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
   
      <img fetchpriority="high" data-aos="fade-right" data-aos-duration="1200" src="../assets/4827.jpg" alt="Api koppeling maken" class="rounded-xl h-72 md:h-[60dvh] w-full">
   
      <div data-aos="fade-left" data-aos-duration="1200" class="px-5 lg:px-0 text-donkerColor">
         <span class="text-orange-500 text-bold text-lg">Automatiseer en Optimaliseer jouw bedrijf</span>
         <h2 class="md:py-5 py-3 leading-10">API Koppeling laten maken</h2>
         <p class="lg:w-[80%]">Wil jij efficiënter werken en systemen naadloos met elkaar laten communiceren? Dan is een API koppeling laten maken de perfecte oplossing. Met een goed ontwikkelde API verbind je verschillende softwareplatformen, bespaar je tijd en voorkom je fouten. Of het nu gaat om een koppeling met je CRM, boekhoudsoftware of een API social media integratie zoals API LinkedIn, API Instagram of API Facebook – een API ontwikkelen zorgt voor geautomatiseerde en efficiënte workflows.</p>
         <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="/contact"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
      </div>
   </div>


   <div class="container mx-auto grid lg:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
      <div class=" grid place-content-center lg:w-[80%] px-5 lg:px-0 text-donkerColor">

         
         <h1 class="lg:w-[80%] mb-5">Wat is een API en waarom heb je het nodig?</h1>
         <p>Een API (Application Programming Interface) is een digitale brug tussen verschillende systemen, waardoor ze veilig en efficiënt gegevens kunnen uitwisselen. <br> Door een API koppeling te laten maken, kunnen jouw bedrijfssystemen automatisch met elkaar communiceren zonder handmatige invoer.</p>
      </div>

      <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 shadow-donkerColor drop-shadow-xl" data-aos="fade-up"  data-aos-duration="500">
           <div class="text-black">
               
               <i class="fa-solid fa-window-maximize text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Social media API’s</h2>
               <p> Geautomatiseerde koppelingen met API Instagram, API Facebook en API LinkedIn.</p>
           </div>

         </div>
         
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-xl shadow-donkerColor"  data-aos="fade-up"  data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-lock text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Boekhoud API koppelingen </h2>
               <p>Verbind je facturatiesysteem met je administratie.</p>
            </div>
         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center drop-shadow-xl shadow-donkerColor p-5" data-aos="fade-up" data-aos-duration="500">
            <div class="text-black">
               <i class="fa-solid fa-users-viewfinder lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl"> API koppelingen voor CRM-systemen</h2>
               <p> Synchroniseer klantdata automatisch.</p>
            </div>
         </div>

          <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 shadow-donkerColor drop-shadow-xl" data-aos="fade-up"  data-aos-duration="500">
           <div class="text-black">
               
               <i class="fa-solid fa-window-maximize text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Webshop API koppelingen</h2>
               <p> Koppel je e-commerceplatform met betalingssystemen of voorraadsystemen.</p>
           </div>

         </div>
       
      </div>
   </div>


   <div class="w-[100vw] h-full relative overflow-hidden">
 <!-- Video als achtergrond -->
 <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/geoptimaliseerd.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <img loading='lazy' src="../assets/optimaized/headerimg.webp" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">
  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative text-donkerColor pt-12 ">
 
   <div class=" text-center container mx-auto">
      <h2 class="text-3xl">De Voordelen van een API Ontwikkelen</h2>
   </div>

   <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center gap-10 p-10">
      <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
           <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-handshake bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>	Betere samenwerking</h2>
           <p>API’s stellen bedrijven in staat om eenvoudig te integreren met leveranciers, dealers en partners via portalen of online platformen. Ze geven hen toegang tot specifieke diensten en data in real-time en maken het mogelijk om bepaalde processen te automatiseren. Hierdoor ontstaan er nieuwe samenwerkingsmogelijkheden en ecosystemen waarin je via API’s waarde kunt delen.</p>
      </div>

      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10  " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-euro-sign  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Kostenbesparing</h2>
           <p>API’s maken het mogelijk om bestaande systemen en services te hergebruiken. Hierdoor hoef je geen tijd en middelen te investeren in het ontwikkelen van functionaliteiten die elders al bestaan. Dit levert een besparing op in je ontwikkelingskosten én onderhoudskosten. API’s maken het bovendien mogelijk om handmatige, tijdrovende taken te automatiseren en zo te besparen op arbeidskosten.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-arrows-to-eye  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>	Schaalbaarheid</h2>
           <p>API’s stellen systemen in staat om onafhankelijk van elkaar te functioneren. Hierdoor kun je elk systeem (of service) apart opschalen zonder dat je de hele infrastructuur hoeft aan te passen. Dit betekent dat als één service meer vraag krijgt je alleen die specifieke API-systeemcomponent hoeft op te schalen, zonder dat andere onderdelen van de applicatie worden beïnvloed</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-rocket  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> 	Snellere innovatie</h2>
           <p>API’s maken snellere ontwikkeling en innovatie mogelijk door gestandaardiseerde, herbruikbare functies aan te bieden en integratie met externe technologieën mogelijk te maken. Ontwikkelaars kunnen hierdoor makkelijk en snel nieuwe ideeën testen en verbeteren. En zo kunnen bedrijven sneller reageren op marktkansen en inspelen op veranderende behoeften.</p>
        
      </div>
   </div>

   
  </div>
 
</div>


<div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
   
      <div class="w-screen md:absolute md:top-0">
           
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block animate-scroll">
                    <img loading='lazy' src="../assets/logo.png" alt="vueLogo" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/Vite.svg" alt="ViteLogo" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/tailwindcss.png" alt="TailwindLogo" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/firebase.webp" alt="Firebaselogo" class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/nodeJS.png" alt="NodeJsLogo" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    
                    <img loading='lazy' src="../assets/logo.png" alt="vueLogo"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/Vite.svg" alt="ViteLogo"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/tailwindcss.png" alt="TailwindLogo"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/firebase.webp" alt="Firebaselogo"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/nodeJS.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
        <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

            <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
               <img loading='lazy' src="../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
            </div>

            <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
               <h1 class="pb-5">API Ontwikkelen? Kies voor Swens Software!</h1>
               <p class="lg:w-[80%]">Wil jij een API koppeling laten maken die jouw bedrijf helpt groeien? Swens Software is specialist in API ontwikkelen, van simpele integraties tot complexe koppelingen.</p>
               <br>
               <ul>
                  <li><span class='text-white'>✔</span> Maatwerk API koppelingen – Perfect afgestemd op jouw bedrijfsprocessen.</li>
                  <li><span class='text-white'>✔</span> Veilige en betrouwbare API’s – Jouw data is optimaal beschermd.</li>
                  <li><span class='text-white'>✔</span> Gebruiksvriendelijke oplossingen – Simpele integratie zonder technische hoofdbrekens.</li>
                  <li><span class='text-white'>✔</span> Schaalbare API’s – Jouw API groeit mee met je onderneming</li>
               </ul>
               <br>
               <p class="lg:w-[80%]"> Wil jij een API laten maken om jouw systemen slimmer en efficiënter te laten samenwerken? Neem contact op met Swens Software en ontdek de mogelijkheden van een maatwerk API koppeling! </p>
               <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
          
            </div>

            
            
         </div>
   

  
</div>

   </div>
</template>


<style scoped>
@keyframes scrollanimatie {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: scrollanimatie 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
