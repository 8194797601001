
<script setup>
import { useHead } from '@vueuse/head'

useHead({
title: 'Script op maat laten maken.',
meta: [
   {name: 'description' , content: 'Script nodig om iets te laten automatischeren? Of iets anders? Er kan veel!'}
],
link: [
   { rel: 'canonical', href: 'https://swenssoftware.nl/diensten/script' },
],
})
</script>

<template>
   <div>
   <div class="container mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
   
      <img fetchpriority="high" data-aos="fade-right" data-aos-duration="1200" src="../assets/optimaized/SoftwareCheck.webp" alt="Script laten maken" class="rounded-xl h-72 md:h-[60dvh] w-full drop-shadow-lg">
   
      <div data-aos="fade-left" data-aos-duration="1200" class="px-5 lg:px-0 text-donkerColor">
         <span class="text-orange-500 text-bold text-lg">Maatwerk scripts voor jouw bedrijf.</span>
         <h2 class="md:py-5 py-3">Script laten schrijven</h2>
         <p class="lg:w-[80%] ">Wil je een script laten schrijven om processen te automatiseren, data te verwerken of een specifieke taak uit te voeren? Bij SwensSoftware ontwikkelen we op maat gemaakte script software die jouw workflow efficiënter maakt en tijd bespaart.</p>
         <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="/contact"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
      </div>
   </div>


   <div class="md:container md:mx-auto grid md:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
      <div class=" grid place-content-center lg:w-[80%] px-5 lg:px-0 text-donkerColor">

         
         <h1 class="lg:w-[90%] mb-5 leading-10">Waarom een script laten schrijven?</h1>
         <p>Een goed geschreven script kan veel taken automatiseren</p>
      </div>

      <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-lg " data-aos="fade-up"  data-aos-duration="500">
           <div class="text-donkerColor">
               
               <i class="fa-solid fa-file-export text-md lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Gegevens verwerken en exporteren</h2>
               <p>Laat een script schrijven om automatisch data te verwerken, zoals het exporteren van CSV-, Excel-bestanden of API-gegevens.</p>
           </div>

         </div>
         
         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5 drop-shadow-lg"  data-aos="fade-up"  data-aos-duration="500">
            <div class="text-donkerColor">
               <i class="fa-solid fa-truck-fast lg:text-2xl bg-donkerColor text-ligteColor h-10 w-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Handelingen versnellen met je script </h2>
               <p> Bespaar tijd met script software die bulkbewerkingen uitvoert, zoals het hernoemen van bestanden of het invoeren van data.</p>
              </div>
         </div>


         <div class="lg:h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
            <div class="text-donkerColor">
               <i class="fa-solid fa-gears lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h2 class="text-xl">Integraties tussen verschillende software</h2>
               <p>Laat een software script schrijven dat systemen koppelt, zoals een CRM, CMS of boekhoudsoftware voor naadloze gegevensuitwisseling.</p>
               <router-link to="/diensten/web-applicatie" class="">  
                  <i class="fa-solid fa-arrow-right-long fa-2xl border-black border-2 p-3 rounded-full hover:bg-hoverColor duration-300 transition mt-5"></i>
                  <span class="sr-only">Bekijk web applicatie</span>
               </router-link>
            </div>
         </div>
       
      </div>
   </div>


   <div class="w-[100vw] lg:min-h-[100vh] h-fit relative overflow-hidden ">
  <!-- Video als achtergrond -->
  <video class="absolute top-0 left-0 w-full h-full object-cover hidden md:block" autoplay loop muted>
    <source src="../assets/video/geoptimaliseerd.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <img loading='lazy' src="../assets/optimaized/headerimg.webp" alt="Swenssoftware software maatwerk" class="absolute top-0 left-0 w-full h-full object-cover block md:hidden">
  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative text-donkerColor pt-12 ">
 
   <div class=" text-center">
      <h3 class="text-3xl">Voordelen van een software script laten schrijven</h3>
   </div>

   <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center h-fit  gap-10 p-10">
      <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-right">
           <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-clock-rotate-left bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Tijdsbesparing</h2>
           <p>Voorkom handmatig werk en verhoog de productiviteit.</p>
      </div>

      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10  w-screen md:w-auto" data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-bug bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Foutreductie</h2>
           <p>Scripts voeren taken nauwkeurig en zonder menselijke fouten uit.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-right">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-calendar-check bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Efficiëntie</h2>
           <p>Snellere en geautomatiseerde processen besparen kosten.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-left">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-scale-balanced bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Schaalbaarheid</h2>
           <p>Een goed script groeit mee met jouw bedrijf.</p>
        
      </div>
        <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 w-screen md:w-auto" data-aos="fade-up-right">
         <h2 class="flex items-center justify-center pb-5"> <i class="fa-solid fa-gears bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Koppelingen & automatisering</h2>
           <p> Integreer naadloos met bestaande systemen</p>
        
      </div>
     
   </div>

   
  </div>
 
</div>


<div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
   
      <div class="w-screen md:absolute md:top-0">
           
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block naarSectie">
                    <img loading='lazy' src="../assets/logo.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/Vite.svg" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/tailwindcss.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/firebase.webp" alt="Maatwerk Software web applicatie" class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/GithubLogo.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/nodeJS.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/figmaAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/relumeAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    
                    <img loading='lazy' src="../assets/logo.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/Vite.svg" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/tailwindcss.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/firebase.webp" alt="Maatwerk Software web applicatie"  class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/GithubLogo.png" alt="Maatwerk Software web applicatie" class='w-25 h-20 mx-20'>
                    <img loading='lazy' src="../assets/nodeJS.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/figmaAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                    <img loading='lazy' src="../assets/relumeAfbeelding.png" alt="Maatwerk Software web applicatie"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
         <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

            <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
               <img loading='lazy'  src="../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
            </div>

            <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
               <h1 class="pb-5">Maatwerk script software voor elk platform</h1>
               <p class="md:w-[90%] py-3 z-50">Of het nu gaat om een Python-script voor data-analyse, een PHP-script voor een webapplicatie of een JavaScript-automatisering voor een website – wij zorgen voor een betrouwbare en efficiënte oplossing.</p>
             
               <p class='w-[70%] md:w-auto'>
                  Wil jij een web applicatie laten maken die jouw onderneming efficiënter maakt? Kies voor een maatwerk online oplossing die perfect aansluit bij jouw wensen. Neem contact op met Swens Software en ontdek hoe wij jouw perfecte web app ontwikkelen! 🚀
               </p>
               <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>
          
            </div>
            <div class="absolute -bottom-[9rem] right-0 sm:pl-10 sm:bg-hoverColor rounded-l-full md:hidden block z-10">
               <img loading='lazy' src="../assets/LeunSwen.png" alt="Contact SwensSoftware" class="">
            </div>
            
           
               
            
         </div>
   

  
</div>

  </div>
</template>


<style scoped>
@keyframes naarSectie {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .naarSectie {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: naarSectie 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
