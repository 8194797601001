<template>
  <div>
    
    <headerSection />
    <cursor />
 
    <coockiebanner v-if="showBanner" @hideBanner="showBanner = false" />


  <router-view />
  <footerSection />
  </div>
</template>

<script setup>
import { inject, ref, onMounted } from 'vue';

import cursor from './components/cursorComponent.vue';
import headerSection from './components/headerSection.vue';
import footerSection from './components/footerSection.vue';
import coockiebanner from './components/coockieSection.vue';

// Injecteer PostHog
const posthog = inject('$posthog');

const showBanner = ref(false);

onMounted(() => {
  showBanner.value = true; // Toon cookie banner
  if (posthog) {
    console.log("✅ PostHog is correct geladen.");
    console.log("🚨 showBanner waarde:", showBanner.value);
    const hasOptedIn = posthog.has_opted_in_capturing();
    const hasOptedOut = posthog.has_opted_out_capturing();

    console.log("Opt-in status:", hasOptedIn);
    console.log("Opt-out status:", hasOptedOut);

    if (!hasOptedIn && !hasOptedOut) {
      console.log("🚨 Nog geen keuze gemaakt, tracking standaard UIT zetten.");
      posthog.opt_out_capturing(); // Zorgt ervoor dat PostHog standaard uit staat
      
    }
  } else {
    console.error("❌ PostHog is niet beschikbaar in App.vue.");
    showBanner.value = true;
  }
});

</script>

<style scoped>
.fadeCoockies-enter-active,
.fadeCoockies-leave-active {
  transition: none;
}
.fadeCoockies-enter,
.fadeCoockies-leave-to {
  opacity: 0;
}
</style>
