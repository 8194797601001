<script setup>
import { useHead } from '@vueuse/head'

useHead({
title: 'IT oplossing in de Achterhoek',
meta: [
   {name: 'description' , content: 'Jouw maatwerk It oplossing in de achterhoek. Van web applicatie tot portalen, wij helpen je verder.'}
],
link: [
   { rel: 'canonical', href: 'https://swenssoftware.nl/werkgebied/achterhoek' },
],
})
</script>

<template>

   <div class="">
   <div class="md:container md:mx-auto grid lg:grid-cols-2 pt-52 pb-10 gap-10 lg:h-[65dvh]">
   
      <img src="../../assets/optimaized/Platform.webp" alt="Maatwerk App applicatie" class="rounded-xl h-72 md:h-[60dvh] w-full">
   
      <div class="px-5 lg:px-0 text-donkerColor">
            <span class="text-orange-500 text-bold text-lg">Creating Smart Solutions</span>
            <h2 class="md:py-5 py-3">IT oplossing in de Achterhoek</h2>
            <p class="lg:w-[80%]">
                De Achterhoek staat bekend om haar ondernemerschap en innovatie. Om jouw bedrijf in deze regio te ondersteunen, bieden wij op maat gemaakte IT-oplossingen. Van webapplicaties en app-applicaties tot portals en API-koppelingen – wij zorgen ervoor dat jouw digitale processen soepel verlopen en jouw onderneming blijft groeien.
            </p>   
            <button  class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-500"><a class='text-textColor' href="/contact"> Contact  <i class="fa-solid fa-arrow-right"></i></a></button>
   
        </div>
   </div>


   <div class="container mx-auto grid lg:grid-cols-2 pt-20 md:pt-72 pb-10 gap-10 ">
      <div class=" grid place-content-center lg:w-[80%] px-5 lg:p-0 text-donkerColor">

         
         <h1>Start vandaag nog!</h1>

         <p>Wil je jouw bedrijf naar een hoger niveau tillen met slimme web application? Neem contact met ons op voor een vrijblijvend gesprek. Samen creëren we digitale oplossingen die werken.</p>  </div>

      <div class="grid lg:grid-cols-2 gap-5 grid-rows-2 justify-content-stretch pb-52">

          <div class="lg:min-h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
           <div class="text-donkerColor">
               
            <i class="fa-solid fa-users-viewfinder lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-lg">Webapplicaties ontwikkelen: Efficiëntie en gebruiksgemak</h3>
               <p>Een webapplicatie biedt jouw bedrijf de mogelijkheid om workflows te optimaliseren en data centraal te beheren. Denk aan een dashboard voor inzicht in jouw bedrijfsprocessen, of een systeem om klantgegevens efficiënt bij te houden. Onze webapplicaties worden volledig op maat gemaakt, zodat ze perfect aansluiten op jouw behoeften én gemakkelijk te integreren zijn met bestaande systemen.</p>
           </div>

         </div>


         <div class="lg:min-h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
            <div class="text-donkerColor">
               <i class="fa-solid fa-users-viewfinder lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-lg">App-applicaties: Altijd en overal verbonden</h3>
               <p>Met een op maat gemaakte app zorg je ervoor dat jouw klanten en medewerkers altijd toegang hebben tot belangrijke functionaliteiten. Of het nu gaat om een native app voor iOS en Android, of een hybride app die snel en efficiënt op meerdere platforms werkt – wij bouwen een gebruiksvriendelijke app die jouw bedrijf versterkt.</p>
            </div>
         </div>
         

         <div class="lg:min-h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
            <div class="text-donkerColor">
               <i class="fa-solid fa-users-viewfinder lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-lg">Portals: Centraal samenwerken</h3>
               <p>Een portal is de ideale oplossing om jouw medewerkers, klanten of partners een centrale omgeving te bieden. Of het nu gaat om een klantenportaal, een intranet voor medewerkers of een uitgebreide self-service omgeving – wij ontwikkelen portals die samenwerken en communicatie vereenvoudigen.</p>
            </div>
         </div>

         <div class="lg:min-h-[50dvh] bg-ligteColor rounded-xl text-center grid place-content-center p-5  drop-shadow-lg" data-aos="fade-up" data-aos-duration="500">
            <div class="text-donkerColor">
               <i class="fa-solid fa-users-viewfinder lg:text-2xl bg-donkerColor text-ligteColor w-10 h-10 lg:h-14 lg:w-14 p-5 rounded-full mb-5"></i>
               <h3 class="text-lg"> API-koppelingen: Alles naadloos verbonden</h3>
               <p>Gegevens synchroniseren tussen verschillende systemen kan tijdrovend zijn. Met API-koppelingen zorgen wij ervoor dat jouw software en applicaties probleemloos met elkaar communiceren. Dit bespaart tijd, minimaliseert fouten en zorgt voor een efficiëntere bedrijfsvoering.</p>
            </div>
         </div>
       
      </div>
   </div>


   <div class="w-[100vw] h-full relative overflow-hidden">
  <!-- Video als achtergrond -->
  <video class="absolute top-0 left-0 w-full h-full object-cover" autoplay loop muted>
    <source src="../../assets/video/geoptimaliseerd.mp4" type="video/mp4" />
    Je browser ondersteunt geen video.
  </video>

  <!-- Gradient overlay -->
  <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-donkerColor via-white to-ligteColor opacity-50"></div>

  <!-- Content (optioneel) -->
  <div class="z-10 relative text-donkerColor pt-12 ">
 
   <div class=" text-center">
      <h3 class="text-3xl">Voordelen van app-applicaties</h3>
   </div>

   <div class="md:container mx-auto grid lg:grid-cols-2 place-content-center  gap-10 p-10">
      <div class="bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
           <h2 class="flex items-center justify-center"> <i class="fa-solid fa-bolt bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Efficiëntie</h2>
           <p>Apps zijn speciaal ontwikkeld voor mobiele apparaten, waardoor ze optimaal gebruikmaken van hardware zoals camera’s, GPS en sensoren. Dit versnelt workflows en maakt complexe taken eenvoudiger.</p>
      </div>

      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10  " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center"> <i class="fa-solid fa-gear  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Schaalbaarheid</h2>
           <p>Apps kunnen eenvoudig worden uitgebreid met nieuwe functies naarmate je bedrijf groeit. Updates zijn snel uit te rollen via app stores, zodat je gebruikers altijd toegang hebben tot de nieuwste mogelijkheden.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-right">
         <h2 class="flex items-center justify-center"> <i class="fa-solid fa-fingerprint  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i>Toegankelijkheid</h2>
           <p>Apps zijn altijd binnen handbereik. Gebruikers kunnen eenvoudig toegang krijgen tot belangrijke functies en informatie, zelfs offline, dankzij slimme opslagmogelijkheden.</p>
        
      </div>
      <div class=" bg-ligteColor text-center min-h-[300px] rounded-2xl py-5 px-10 " data-aos="fade-up-left">
         <h2 class="flex items-center justify-center"> <i class="fa-solid fa-list-check  bg-donkerColor text-ligteColor w-6 h-6 p-3 rounded-full mr-5 "></i> Personalisatie</h2>
           <p>Met een app kun je de ervaring volledig aanpassen aan de gebruiker. Denk aan persoonlijke instellingen, pushmeldingen en relevante content op basis van gebruikersgedrag.

</p>
        
      </div>
   </div>

   
  </div>
 
</div>


<div class="w-[100vw] lg:h-[100vh] h-full relative bg-donkerColor">
   
      <div class="w-screen md:absolute md:top-0">
           
            <div class="overflow-hidden pt-10 pb-16">
                <div class="inline-block animate-scroll">
                    <img src="../../assets/logo.png" alt="vueLogo" class='w-25 h-20 mx-20'>
                    <img src="../../assets/Vite.svg" alt="ViteLogo" class='w-25 h-20 mx-20'>
                    <img src="../../assets/tailwindcss.png" alt="TailwindLogo" class='w-25 h-20 mx-20'>
                    <img src="../../assets/firebase.webp" alt="Firebaselogo" class='w-25 h-20  mx-20'>
                    <img src="../../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                    <img src="../../assets/nodeJS.png" alt="NodeJsLogo" class='w-25 h-20 mx-20'>
                    <img src="../../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img src="../../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img src="../../assets/logo.png" alt="vueLogo"  class='w-25 h-20 mx-20'>
                    <img src="../../assets/Vite.svg" alt="ViteLogo"  class='w-25 h-20 mx-20'>
                    <img src="../../assets/tailwindcss.png" alt="TailwindLogo"  class='w-25 h-20 mx-20'>
                    <img src="../../assets/firebase.webp" alt="Firebaselogo"  class='w-25 h-20 mx-20'>
                    <img src="../../assets/GithubLogo.png" alt="GithubLogo" class='w-25 h-20 mx-20'>
                    <img src="../../assets/nodeJS.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img src="../../assets/figmaAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                    <img src="../../assets/relumeAfbeelding.png" alt="NodeJsLogo"  class='w-25 h-20  mx-20'>
                </div>
            </div>
        </div>
        <div class="pt-[8.2%] grid md:grid-cols-2  lg:container mx-auto relative">

         <div class="bg-hoverColor rounded-r-full relative xl:w-[80%] xl:h-[90%] mt-52 md:block hidden">
            <img src="../../assets/KijkrechtsSwen.png" alt="Contact SwensSoftware" class="  absolute bottom-0 xl:-left-10">
         </div>

         <div class="gap-5 text-white px-5 lg:px-0 md:pt-28">
            <h1 class="pb-5">Jouw it oplossing in de Achterhoek.</h1>
            <p class="w-[70%] md:w-[90%] py-3 z-50">Wil je graag een applicatie laten ontwikkelen of op maat software laten maken? Dan zit je bij SwensSoftware goed. Als webapplicatie-bouwer beschikken we over de expertise en ervaring die nodig is om de web applicatie te maken die jouw business naar het volgende niveau tilt. Bovendien zorgen we er ook altijd voor dat die nieuwe oplossing probleemloos integreert met al je andere systemen en programma’s. Weten wat een applicatie ontwikkelen gaat kosten of wat er zoal mogelijk is binnen jouw budget? Vul dan het contactformulier in voor een vrijblijvende kennismaking!</p>
            <button class="border-2 border-hoverColor shadow-lg hover:shadow-navbar p-2 md:px-5 my-5 rounded-full  hover:bg-hoverColor transition ease-in-out duration-300"><router-link class='text-textColor' to="/Contact">Neem contact op <i class="fa-solid fa-arrow-right"></i></router-link></button>

         </div>
         <div class="absolute -bottom-[9rem] right-0 sm:pl-10 sm:bg-hoverColor rounded-l-full md:hidden block z-10">
            <img src="../../assets/LeunSwen.png" alt="Contact SwensSoftware" class="">
         </div>


   

      </div>
   

  
</div>

</div>
</template>


<style scoped>
@keyframes boomrang {
      0% {
          transform: translateX(0%);
      }
      100% {
          transform: translateX(-100%);
      }
  }

  .animate-scroll {
          display: flex;
          white-space: nowrap;
          will-change: transform;
          animation: boomrang 25s linear infinite;
  }

  .panel {
  will-change: transform; /* Pre-optimalisatie hint */
  transform: translateZ(0); /* Forceer hardwareversnelling */
}
</style>
