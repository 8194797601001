// ./plugin/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    posthog.init('phc_b54eUQAphQnQVStZmLxgUMPcNB6CvNOSf9SES2aC0Qp', {
      api_host: 'https://eu.i.posthog.com',
      autocapture: false, // Voorkom automatische event tracking
      capture_pageview: false, // Voorkom automatische pageviews
      disable_session_recording: true, // Session replay standaard uitzetten
      loaded: (posthog) => {
        if (!posthog.has_opted_in_capturing()) {
          posthog.opt_out_capturing(); // Zet tracking standaard uit
        }
      }
    });

    // Voeg PostHog toe aan Vue's globale properties
    app.config.globalProperties.$posthog = posthog;

    // Maak PostHog injecteerbaar
    app.provide('$posthog', posthog);
  },
};
